import React from 'react'
import "./Pilot.css"
import passion from "../../assets/images/passion.png";
import innovation from "../../assets/images/innovation.png";
import learning from "../../assets/images/learning.png";
import ownership from "../../assets/images/ownership.png";
import teamwork from "../../assets/images/teamwork.png";

function Pilot() {
    
    return(
        <div className="pilot">
            <p className="pilotchild1">PILOT</p>
            <p className="pilotchild2">
            Our company's core values "PILOT" shape the way we work with our clients, delivering outstanding user experience supported by cutting-edge technologies
            </p>
            <div className="pilotchild3">
                <div className="pilotchild3child">
                    <img src={passion}  alt="" />
                    <p style={{fontSize:"18px" , fontWeight:"700" , lineHeight:"23px" , color:"rgb(14, 49, 76)"}} >Passion</p>
                    <p style={{fontSize:"15px" , fontWeight:"400" , lineHeight:"26px" , color:"rgb(14, 49, 76)"}} >Know your passion. Follow it. Dream it. Live it.</p>
                </div>
                <div className="pilotchild3child">
                    <img src={innovation} alt="" />
                    <p  style={{fontSize:"18px" , fontWeight:"700" , lineHeight:"23px" , color:"rgb(14, 49, 76)"}} >Innovation</p>
                    <p style={{fontSize:"15px" , fontWeight:"400" , lineHeight:"26px" , color:"rgb(14, 49, 76)"}} >Innovation distinguishes a leader and a follower and we are leader</p>
                </div>
                <div className="pilotchild3child">
                    <img src={learning} alt="" />
                    <p style={{fontSize:"18px" , fontWeight:"700" , lineHeight:"23px" , color:"rgb(14, 49, 76)"}} >Learning</p>
                    <p style={{fontSize:"15px" , fontWeight:"400" , lineHeight:"26px" , color:"rgb(14, 49, 76)"}} >Be humble be teachable and always keep learning</p>
                </div>
                <div className="pilotchild3child">
                    <img src={ownership} alt="" />
                    <p style={{fontSize:"18px" , fontWeight:"700" , lineHeight:"23px" , color:"rgb(14, 49, 76)"}} >Ownership</p>
                    <p style={{fontSize:"15px" , fontWeight:"400" , lineHeight:"26px" , color:"rgb(14, 49, 76)"}} >Claim ownership of your own</p>
                </div>
                <div className="pilotchild3child">
                    <img src={teamwork} alt="" />
                    <p style={{fontSize:"18px" , fontWeight:"700" , lineHeight:"23px" , color:"rgb(14, 49, 76)"}} >Teamwork</p>
                    <p style={{fontSize:"15px" , fontWeight:"400" , lineHeight:"26px" , color:"rgb(14, 49, 76)"}} >Teamworks makes the dream work</p>
                </div>
            </div>
        </div>
    )
}

export default Pilot;