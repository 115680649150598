import React from 'react'
import './Idea.css'

function Idea() {
    return(
        <div className="idea" >
            <p  className="ideachild1">Tell us about your idea, and we’ll make it happen.</p>
            <p className="ideachild2">Have an exciting project in mind? Or maybe would like to improve your current setup? We’d be happy to discuss it with you.</p>
            <button className="ideachild3">Lets Get Started</button>
            
        </div>
    )
}

export default Idea;