import React from 'react'
import  './Contact.css'
import flag from '../../assets/images/american.png'

function Contact() {
    return(
        <div className="contact" >
            <p className="contactchild1">Contact with us</p>
            <div className="contactchild2">
                    <div className="contactchild2child1">
                        <img src={flag} alt="" width="40px" />
                        <p style={{fontSize:"18px" , fontWeight:"600" ,lineHeight:"23px" , color:"rgb(44, 62, 80)" }} >Contact Us in USA</p>
                        <div>
                            <p style={{fontSize:"15px" , fontWeight:"400" ,lineHeight:"26px" , color:"rgb(14, 49, 76)" }}  >ECQUINT SOLUTIONS</p>
                            <p style={{fontSize:"15px" , fontWeight:"400" ,lineHeight:"26px" , color:"rgb(14, 49, 76)" }}  >Address : 219 HARVARD ST, CANTON, MI, 48188 </p>
                            <p style={{fontSize:"16px" , fontWeight:"400" ,lineHeight:"26px" , color:"rgb(14, 49, 76)" }}  >Email : <a href="mailto:info@ecquintsolutions.com">info@ecquintsolutions.com</a> </p>
                            
                        </div>
                    </div>
                    <div className="contactchild2child2">
                        <p style={{fontSize:"18px" , fontWeight:"600" ,lineHeight:"23px" , color:"gb(44, 62, 80)"  , paddingLeft:"20px"}}  >Reach us quickly</p>
                        <form action="">
                            <input type="text" className="input5"   placeholder="Enter Name" />
                            <input type="text" className="input5"   placeholder="Enter Email"  />
                            <input type="text" className="input5"   placeholder="Your Phone"  />
                            <input type="text" className="input5"   placeholder="Your Company"  />
                            <textarea  className="input6"  placeholder="Message" ></textarea>
                            
                        </form>
                    </div>
            </div>
            
        </div>
    )
}

export default Contact;