import React , { useRef }  from 'react'
import "./Nav.css"

function Nav() {

  const navback = useRef(null);

    window.addEventListener("scroll", function() {
        const navbar = document.getElementById("navbar");
      
        // Add or remove the class based on scroll position
        if (window.scrollY > 40) {
          navbar.classList.add("navbar-scrolled");
        } else {
          navbar.classList.remove("navbar-scrolled");
        }
      });


      function navbackchange() {

        if (navback.current) {
          console.log(navback.current); // Logs the <div> element
          navback.current.style.backgroundColor = 'white';
          navback.current.style.borderBottom = '1px solid rgb(62, 200, 150)'; // Example DOM manipulation
        }
        
      }

      function navv() {
        if (navback.current) {
          console.log(navback.current); // Logs the <div> element
          navback.current.style.backgroundColor = 'transparent';
          navback.current.style.borderBottom = '0px solid rgb(62, 200, 150)'; // Example DOM manipulation
        }
        
      }

    return(
        <div className="Nav" ref={navback}  id="navbar">
           <span style={{paddingLeft:"10%" }} >
           <h2 style={{color:"rgb(67,165,141)" , fontSize:"21px" }}>ECQUINT</h2>
           <p style={{color:"rgb(67,165,141)" , fontWeight:"500" , fontSize:"17px" }} >SOLUTIONS</p>
           </span>
           <div className="navlogo" onClick={navbackchange}  onMouseOver={navbackchange} onMouseOut={navv}>
              <div className="span1"></div>
              <div className="span2"></div>
              <div className="span3"></div>
           </div>
           <div className="mobilenav">
                <a href="/">Home</a>
                <a href="/">Sevices</a>
                <a href="/">company</a>
                <a href="/">contact us</a>

           </div>
           <div className="navchild1">
                <a href="/">Home</a>
                <a href="/">Sevices</a>
                <a href="/">company</a>
                <a href="/">contact us</a>
           </div>
        </div>
    )
    
}

export default Nav;