import React from 'react'
import "./Proven.css"
import healthcare from "../../assets/images/healthcare.png";
import homeservices from "../../assets/images/homeservices.png";
import energy from "../../assets/images/energy.png";
import telecom from "../../assets/images/telecom.png";
import payment from "../../assets/images/payment.png";


function Proven() {

    return(
        <div  className="proven" >

            <p className="provenchild1">
                Industries Of Proven Expertise
            </p>
            <p className="provenchild2"> We take pride in helping our clients deliver marvelous results when it comes to their projects. From data to performance, we’ve got you covered.</p>
            <div className="provenchild3">
                <div className="provenchild3child1">
                    <p style={{fontFamily:'Open Sans, sans-serif' , fontSize:"20px" ,fontWeight:"200"  , color:"rgb(46, 46, 46)" , lineHeight:"32px"}} >See some of the industries we have vast experience working straightforward solutions to complex business challenges.</p>
                    <div className="provechilds">
                        <span>
                        <img src={healthcare} width="70px" alt="" />
                            <p>Healthcare</p>
                        </span>
                        <span>
                            <img src={homeservices} width="70px" alt="" />
                            <p>Home Services</p>
                        </span>
                        <span>
                            <img src={energy} width="70px" alt="" />
                            <p>Energy</p>
                        </span>
                        <span>
                            <img src={telecom} width="70px" alt="" />
                            <p>Telecom</p>
                        </span>
                        <span>
                            <img src={payment} width="70px" alt="" />
                            <p>Payments</p>
                        </span>
                    </div>
                </div>
                <div className="provenchild3child2">
                    <p style={{fontFamily:" Montserrat, sans-serif" , fontSize:"32px" , fontWeight:"600" ,lineHeight:"50px"}} >Have a Project?</p>
                    <p style={{fontFamily:"'Open Sans', sans-serif" , fontSize:"19px" , fontWeight:"400" , lineHeight:"30px" , width:"auto"}} >Tell us about your idea, and we’ll make it happen.</p>
                    <button>Let's Get Started</button>
                </div>
            </div>

        </div>
    )
    
}

export default Proven;