import React from 'react'
import  './Pricing.css'
import time from '../../assets/images/time.svg'
import cost from '../../assets/images/cost.svg'
import team from '../../assets/images/team.svg'

function Pricing() {
    return(
        <div className="pricing"  >
            <div className="pricingchild1">
                <p style={{fontSize:"32px" , fontWeight:"600" , lineHeight:"40px" , color:"rgb(44, 62, 80)"}} >Our Pricing Model</p>
                <p style={{fontSize:"15px" , fontWeight:"400" , lineHeight:"26px" , color:"rgb(14, 49, 76)"}} >We bill the way your pricing strategy demands, find the pricing model that works the best for your business needs. Our three different standard pricing models can fit any software development and maintenance business requirement, Also Bravery is always very flexible on pricing to meet the mutual benefits on business engagement</p>
                <button>Learn More</button>

            </div>
            <div className="pricingchild2">
                <div>
                    <div>
                        <img src={cost} alt="" />
                        <p style={{fontSize:"30px" , fontWeight:"600" , lineHeight:"35px" , color:"rgb(44, 62, 80)"}} >Fixed Cost</p>
                        <p style={{fontSize:"15px" , fontWeight:"400" , lineHeight:"26px" , color:"rgb(14, 49, 76)"}}  >In this model, the price is fixed and so is the quantity of work. The scope of work is clear and well mentioned earlier. Wireframes are essential for the team involved in development in order to put their hours and include all the features.</p>
                    </div>
                </div>
                <div>
                    <div>
                    <img src={team} alt="" />
                    <p style={{fontSize:"30px" , fontWeight:"600" , lineHeight:"35px" , color:"rgb(44, 62, 80)"}} >Dedicated Team</p>
                    <p style={{fontSize:"15px" , fontWeight:"400" , lineHeight:"26px" , color:"rgb(14, 49, 76)"}} >Hire a PDQ team to start your next technology project or extend your development team with our talent pool of highly-qualified remote software Dev and QA Engineers. "You Manage & We Engineer"</p>
                    </div>
                    
                </div>            
            </div>
            <div className="pricingchild3">
                <div>
                <img src={time} alt="" />
                <p style={{fontSize:"30px" , fontWeight:"600" , lineHeight:"35px" , color:"rgb(44, 62, 80)"}} >Time & Material</p>
                <p style={{fontSize:"15px" , fontWeight:"400" , lineHeight:"26px" , color:"rgb(14, 49, 76)"}} >In this model, You pay for the completed work at regular intervals. You got a crucial role in the development process of the software product. So, good cooperation between the developer team and your team is the heart of T&M.</p>
                </div>
            </div>
        </div>
    )
}

export default Pricing;