import React from 'react'
import "./Offer.css"
import wrapper from "../../assets/images/waves.png";
import Maintenance from "../../assets/images/maintenance.png";
import Modernization from "../../assets/images/modernization.png";
import Business from "../../assets/images/Business.png";
import L1 from "../../assets/images/l1.png";
import Mobile from "../../assets/images/mobile.png";
import Application from "../../assets/images/application.png";

function Offer() {
    
    return(
        <div className="offer" >
            <p className="offerchild1">WHAT WE OFFER</p>
            <p className="offerchild2">
            Our expert in-house team uses cutting edge technologies to build softwares and custom web applications that are stunning, robust and scalable.</p>
            <div className="offerchild3">

                <div className="offerchild3child">
                    <img src={Application} width="90px" alt="" />
                    <p style={{fontSize:"20px" , fontWeight:"600" , lineHeight:"33px" , textAlign:"start", color:"rgb(44, 62, 80)"}} >Application Development</p>
                    <p  style={{fontSize:"15px" , fontWeight:"400" , lineHeight:"24px" , color:"rgb(120, 120, 120)"}} >Take your online business to a new level by developing an interactive, user-engaging and secure website developed by us. We will address all your business needs</p>
                </div>
                <div className="offerchild3child">
                    <img src={Modernization} width="90px" alt="" />
                    <p style={{fontSize:"20px" , fontWeight:"600" , lineHeight:"33px" , textAlign:"start", color:"rgb(44, 62, 80)"}} >Modernization</p>
                    <p  style={{fontSize:"15px" , fontWeight:"400" , lineHeight:"24px" , color:"rgb(120, 120, 120)"}} >It is the one of our core strength, Bravery worked on various migration project to convert standalone and legacy applications to newer technologies applications</p>
                </div>
                <div className="offerchild3child">
                    <img src={Maintenance} width="90px" alt="" />
                    <p style={{fontSize:"20px" , fontWeight:"600" , lineHeight:"33px" , textAlign:"start", color:"rgb(44, 62, 80)"}} >Maintenance & Support</p>
                    <p  style={{fontSize:"15px" , fontWeight:"400" , lineHeight:"24px" , color:"rgb(120, 120, 120)"}} >By using appropriate tools and technologies we are providing Maintenance & support on client applications, servers, network, database and CI/CD setup</p>
                </div>
                <div className="offerchild3child">
                    <img src={Business} width="90px" alt="" />
                    <p style={{fontSize:"20px" , fontWeight:"600" , lineHeight:"33px" , textAlign:"start", color:"rgb(44, 62, 80)"}} >Business Intelligence</p>
                    <p  style={{fontSize:"15px" , fontWeight:"400" , lineHeight:"24px" , color:"rgb(120, 120, 120)"}} >Our team takes pride on providing business intelligence services to open up the new business opportunities for our clients from their existing business application data</p>
                </div>
                <div className="offerchild3child">
                    <img src={Mobile} width="90px" alt="" />
                    <p style={{fontSize:"20px" , fontWeight:"600" , lineHeight:"33px" , textAlign:"start" , color:"rgb(44, 62, 80)"}} >Mobile App Development</p>
                    <p  style={{fontSize:"15px" , fontWeight:"400" , lineHeight:"24px" , color:"rgb(120, 120, 120)"}} >Responsive and Progressive are default built in feature of all our web application also having good experience in Mobile App development in iOS & Android</p>
                </div>
                <div className="offerchild3child">
                    <img src={L1} width="90px" alt="" />
                    <p style={{fontSize:"20px" , fontWeight:"600" , lineHeight:"33px" , textAlign:"start", color:"rgb(44, 62, 80)"}} >L1 & L2 Support</p>
                    <p  style={{fontSize:"15px" , fontWeight:"400" , lineHeight:"24px" , color:"rgb(120, 120, 120)"}} >Our wonderful support engineering team is providing L1 & L2 support for Desktop and Production support for round the clock business critical application support needs    </p>
                </div>
            </div>
            <button>View Case Study</button>

        <span className="wrapper" ><img src={wrapper}  width="100%" alt="" /></span>
        </div>
    )
}

export default Offer;