import React from 'react'
import  './Footer.css'
import insta from "../../assets/images/instagram.png";
import facebook from "../../assets/images/facebook.png";
import linkedin from "../../assets/images/linkedin.png";

function Footer() {
    return(
        <div className="footer" >
            <div className="Footerchild1">
            <h2 style={{color:"rgb(67,165,141)" , fontSize:"21px" }}>ECQUINT</h2>
            <p style={{color:"rgb(67,165,141)" , fontWeight:"500" , fontSize:"18px" }} >SOLUTIONS</p>
            </div>
            <div className="Footerchild2">
                <img src={linkedin} alt="" />
                <img src={facebook} alt="" />
                <img src={insta} alt="" />
            </div>
            <p className="Footerchild3">Copyright © 2024 Ecquint Solutions, LLC. - All Rights Reserved.</p>
            
        </div>
    )
}

export default Footer;