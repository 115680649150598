import React from 'react'
import Nav from "../../components/Nav/Nav.jsx"
import  "./Home.css";
import one from "../../assets/images/one.png"
import two from "../../assets/images/two.png"
import three from "../../assets/images/three.png"
import four from "../../assets/images/four.png"
import Proven from "../../components/Proven/Proven.jsx";
import Offer from "../../components/Offer/Offer.jsx";
import Pilot from "../../components/Pilot/Pilot.jsx";
import Pricing from "../../components/Pricing/Pricing.jsx";
import Idea from "../../components/Idea/Idea.jsx";
import Contact from "../../components/Contact/Contact.jsx";
import Footer from "../../components/Footer/Footer.jsx";



function Home() {

    return(
        <div>
            <Nav></Nav>
          
            <div className="top">
                <div className="topchild">
                    <div className="bigtext">
                        <p>Your Extended Software Development Team </p>
                        
                    </div>
                    <p style={{fontSize:"20px", fontWeight:"400" , color:"white"}}>Empower digital transformation across various business sectors</p>
                    <button className="btn1" >View Our Solution</button>   
                </div>
                
                
            </div>

            <div className="top2">
                <div className="top2child1">
                    <p style={{fontWeight:"600",fontSize:"40px" , textAlign:"center" , color:"rgb(44,62,80)"}} >WHO WE ARE</p>
                </div>
                <div className="top2child2">
                    <p style={{fontSize:"15px",fontWeight:"400" , lineHeight:"26px" , color:"rgb(56, 56, 56)" , width:"60%" , textAlign:"center" , margin:"auto"}} >Bravery Infotech is a fast growing software development and IT consultant service company providing modern engineering solutions to the clients with development center located in Chennai and Head Office in New Jersey.</p>
                </div>
                <div className="top2child3">
                    <div className="top2child3child">
                        <img src={one} alt=""  width="60px" style={{position:"relative" , left:"50%" , transform:"translateX(-50%)"  }} />
                        <p style={{fontSize:"15px" , fontWeight:"600" , textAlign:"center" , color:"rgb(56, 56, 56)"}}  >Our Goal</p>
                        <p style={{fontSize:"15px" , fontWeight:"400"}} >To deliver robust application to our valued clients provide best service and maintain 100% customer retention.</p>
                    </div>
                    <div className="top2child3child">
                    <img src={two} alt=""  width="60px" style={{position:"relative" , left:"50%" , transform:"translateX(-50%)"  }} />
                        <p style={{fontSize:"15px" , fontWeight:"600" , color:"rgb(56, 56, 56)" , textAlign:"center"}}>Close Collaboration</p>
                        <p style={{fontSize:"15px" , fontWeight:"400" , marginBottom:"0px"}} >We make sure our entire client is completely satisfied with our work. BRAVERY take pride in our ability to quick turnaround request.</p>
                    </div>
                    <div className="top2child3child">
                    <img src={three} alt=""  width="60px" style={{position:"relative" , left:"50%" , transform:"translateX(-50%)"  }} />
                        <p style={{fontSize:"15px" , fontWeight:"600" , color:"rgb(56, 56, 56)" , textAlign:"center"}}>Focus on results</p>
                        <p style={{fontSize:"15px" , fontWeight:"400"}} >To spawn agile and scalable intelligent solutions and deliver more customer value by driving nimble innovation.</p>
                    </div>
                    <div className="top2child3child">
                    <img src={four} alt=""  width="60px" style={{position:"relative" , left:"50%" , transform:"translateX(-50%)"  }} />
                        <p style={{fontSize:"15px" , fontWeight:"600" , color:"rgb(56, 56, 56)" , textAlign:"center"}}>Perfect Process</p>
                        <p style={{fontSize:"15px" , fontWeight:"400"}} >To deliver robust and reliable solution. Complete the project within the deadlines. Deliver product which exceeds client expectation and cost effective.</p>
                    </div>
                </div>

            </div>

            <Proven></Proven>
            <Offer></Offer>
            <Pilot></Pilot>
            <Pricing></Pricing>
            <Idea></Idea>
            <Contact></Contact>
            <Footer></Footer>



           
        </div>
    )
    
}

export default Home;